import classNames from "classnames";

export interface ModalHeaderProps extends React.HTMLAttributes<HTMLElement> {
  [key: string]: any;
  tag?: React.ElementType;
  wrapTag?: React.ElementType;
  toggle?: React.MouseEventHandler<any>;
}

export const ModalHeader: React.FC<ModalHeaderProps> = ({
  tag = "h5",
  wrapTag = "div",
  closeAriaLabel = "Zamknij",
  ...props
}) => {
  let closeButton;
  const { className, cssModule, children, toggle, close, ...attributes } =
    props;

  const Tag = tag;
  const WrapTag = wrapTag;

  const classes = (classNames(className, "modal-header"), cssModule);

  if (!close && toggle) {
    closeButton = (
      <button
        type="button"
        onClick={toggle}
        className={"btn-close mx-6"}
        aria-label={closeAriaLabel}
      >x</button>
    );
  }

  return (
    <WrapTag {...attributes} className={`flex p-3 pt-4 justify-between text-gray-700 ${classes}`}>
      <Tag className={`text-xl  ${"modal-title"}`}>{children}</Tag>
      {close || closeButton}
    </WrapTag>
  );
};
