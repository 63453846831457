import React from 'react';
import classNames, { Mapping } from 'classnames';
import { ColumnProps } from 'reactstrap';
import { isObject } from 'lodash';

const colWidths = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'];

export interface LabelProps
  extends React.LabelHTMLAttributes<HTMLLabelElement> {
  [key: string]: any;
  hidden?: boolean;
  check?: boolean;
  size?: string;
  inline?: boolean;
  disabled?: boolean;
  for?: string;
  tag?: React.ElementType;
  xs?: ColumnProps;
  sm?: ColumnProps;
  md?: ColumnProps;
  lg?: ColumnProps;
  xl?: ColumnProps;
  xxl?: ColumnProps;

  // custom widths
  widths?: string[];
}

const defaultProps = {
  tag: 'label',
  widths: colWidths,
};

const getColumnSizeClass = (isXs: boolean, colWidth: any, colSize: string | boolean) => {
  if (colSize === true || colSize === '') {
    return isXs ? 'col' : `col-${colWidth}`;
  } else if (colSize === 'auto') {
    return isXs ? 'col-auto' : `col-${colWidth}-auto`;
  }

  return isXs ? `col-${colSize}` : `col-${colWidth}-${colSize}`;
};

export const Label: React.FC<LabelProps> = ({ tag = 'label', widths = colWidths, ...props }) => {
  const {
    className,
    cssModule,
    hidden,
    check,
    size,
    for: htmlFor,
    ...attributes
  } = props;

  const Tag = tag;

  const colClasses: string | number | boolean | Mapping | null | undefined = [];

  widths.forEach((colWidth, i) => {
    let columnProp = props[colWidth];

    delete attributes[colWidth];

    if (!columnProp && columnProp !== '') {
      return;
    }

    const isXs = !i;
    let colClass;

    if (isObject(columnProp)) {
      const columnPropAsAny = columnProp as any;

      const colSizeInterfix = isXs ? '-' : `-${colWidth}-`;
      colClass = getColumnSizeClass(isXs, colWidth, columnPropAsAny.size);

      colClasses.push(classNames({
        [colClass]: columnPropAsAny.size || columnPropAsAny.size === '',
        [`order${colSizeInterfix}${columnPropAsAny.order}`]: columnPropAsAny.order || columnPropAsAny.order === 0,
        [`offset${colSizeInterfix}${columnPropAsAny.offset}`]: columnPropAsAny.offset || columnPropAsAny.offset === 0
      }));
    } else {
      colClass = getColumnSizeClass(isXs, colWidth, columnProp);
      colClasses.push(colClass);
    }
  });

  const classes = classNames(
    className,
    hidden ? 'visually-hidden' : false,
    check ? 'form-check-label' : false,
    size ? `col-form-label-${size}` : false,
    colClasses,
    colClasses.length ? 'col-form-label' : 'form-label'
  );

  return (
    <Tag htmlFor={htmlFor} {...attributes} className={classes} />
  );
};

export default Label;
