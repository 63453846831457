import classNames from "classnames";
import React from "react";

type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;

const colWidths = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'];

export interface RowProps
  extends Omit<React.HTMLAttributes<HTMLElement>, "form"> {
  [key: string]: any;
  tag?: React.ElementType;
  noGutters?: boolean;
  form?: boolean;
  xs?: number | string;
  sm?: number | string;
  md?: number | string;
  lg?: number | string;
  xl?: number | string;
  xxl?: number | string;
}

export const Row: React.FC<RowProps> = ({ widths = colWidths, tag = "div", ...props }) => {
  const {
    className,
    cssModule,
    noGutters,
    children,
    form,
    ...attributes
  } = props;

  const Tag = tag;

  const colClasses: any[] = [];

  widths.forEach((colWidth: any, i: any) => {
    let colSize = props[colWidth];

    delete attributes[colWidth];

    if (!colSize) {
      return;
    }

    const isXs = !i;
    colClasses.push(
      isXs ? `row-cols-${colSize}` : `row-cols-${colWidth}-${colSize}`
    );
  });

  const classes = classNames(
    className,
    noGutters ? "gx-0" : null,
    form ? "form-row" : "row",
    colClasses
  );

  return <Tag {...attributes} className={classes}>{children}</Tag>;
};
