import { ToastsProvider } from "components/toasts/toasts-context";
import { createContext, useContext } from "react";
import { UserSessionDto } from "./handle-session";

export const SessionContext = createContext({});

export const useSessionContext = (): UserSessionDto => {
  return useContext(SessionContext);
};

export const SessionProvider: React.FC<{ userSession: UserSessionDto }> = ({
  children,
  userSession,
}) => {
  return (
    <>
      <SessionContext.Provider value={userSession}>
        <ToastsProvider>{children}</ToastsProvider>
      </SessionContext.Provider>
    </>
  );
};
