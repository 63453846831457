import React from "react";
import classNames from "classnames";

export interface NavItemProps extends React.HTMLAttributes<HTMLElement> {
  [key: string]: any;
  tag?: React.ElementType;
  active?: boolean;
}

const defaultProps = {
  tag: "li",
};

export const NavItem = ({ tag = "li", ...props }: NavItemProps) => {
  const { className, cssModule, active, ...attributes } = props;

  const Tag = tag;

  const classes = classNames(
    className,
    "nav-item",
    "",
    active ? "active" : false
  );

  return <Tag {...attributes} className={classes} />;
};
