import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yupLocalePl from "yup-locale-pl";
import printValue from "./printValue";

// Initialize localization here

const castType = (type: string) => {
  if (type === 'number') {
    return 'liczbą';
  }
}

const locale = {
  mixed: {
    notType: ({ path, type, value, originalValue }: any) => {
      let isCast = originalValue != null && originalValue !== value;
      let msg =
        `${path} must be a \`${type}\` type, ` +
        `but the final value was: \`${printValue(value, true)}\`` +
        (isCast
          ? ` (cast from the value \`${printValue(originalValue, true)}\`).`
          : ".");

      if (value === null) {
        // msg += `\n If "null" is intended as an empty value be sure to mark the schema as \`.nullable()\``;
        return "To pole jest polem wymaganym";
      } else {
        return `Pole "${path}" nie może być \`${castType(type)}\` `;
      }
      return msg;
    },
  },
};

yup.setLocale(yupLocalePl);

export { yup, yupResolver };
