import React from 'react';
import classNames from 'classnames';
import { warnOnce } from 'common/debug/warn-once';

const tWind: Record<string, string> = {
  text:'border p-3 outline-blue-600 rounded',
  email: 'border p-3 outline-blue-600 rounded',
  password:'border p-3 outline-blue-600 rounded',
  textarea: 'border p-3 outline-blue-600 rounded',
  select: 'border p-3 outline-blue-600 rounded bg-white',
  checkbox: 'min-h-full border border-blue-600'
}

export type InputType =
  | 'text'
  | 'email'
  | 'select'
  | 'file'
  | 'radio'
  | 'checkbox'
  | 'switch'
  | 'textarea'
  | 'button'
  | 'reset'
  | 'submit'
  | 'date'
  | 'datetime-local'
  | 'hidden'
  | 'image'
  | 'month'
  | 'number'
  | 'range'
  | 'search'
  | 'tel'
  | 'url'
  | 'week'
  | 'password'
  | 'datetime'
  | 'time'
  | 'color';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  [key: string]: any;
  type?: InputType;
  bsSize?: 'lg' | 'sm';
  valid?: boolean;
  invalid?: boolean;
  tag?: React.ElementType;
  innerRef?: React.Ref<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>;
  plaintext?: boolean;
  addon?: boolean;
}

export const Input: React.FC<InputProps> = ({
  type = 'text', ...props
}) => {
  let {
    className,
    cssModule,
    bsSize,
    valid,
    invalid,
    tag,
    addon,
    plaintext,
    innerRef,
    ...attributes
  } = props;

  const checkInput = ['switch', 'radio', 'checkbox'].indexOf(type) > -1;

  const textareaInput = type === 'textarea';
  const selectInput = type === 'select';
  const rangeInput = type === 'range';
  let Tag = tag || (selectInput || textareaInput ? type : 'input');

  let formControlClass: string | null = 'form-control';

  if (plaintext) {
    formControlClass = `${formControlClass}-plaintext`;
    Tag = tag || 'input';
  } else if (rangeInput) {
    formControlClass = 'form-range';
  } else if (selectInput) {
    formControlClass = "form-select";
  } else if (checkInput) {
    if (addon) {
      formControlClass = null;
    } else {
      formControlClass = 'form-check-input';
    }
  }

  const classes = 
    classNames(
      className,
      invalid && 'is-invalid border-red-400',
      valid && 'is-valid',
      formControlClass
    );

  if (Tag === 'input' || (tag && typeof tag === 'function')) {
    attributes.type = type === 'switch' ? 'checkbox' : type;
  }

  if (
    attributes.children &&
    !(
      plaintext ||
      type === 'select' ||
      typeof Tag !== 'string' ||
      Tag === 'select'
    )
  ) {
    warnOnce(
      `Input with a type of "${type}" cannot have children. Please use "value"/"defaultValue" instead.`
    );
    delete attributes.children;
  }

  return <Tag {...attributes} ref={innerRef} className={`${tWind[type]} ${className} ${classes}`} aria-invalid={invalid} />;
}

export default Input;
