export const defaultColors = {
	primary: 'blue-600',
	primaryHover: 'blue-700',
	success: 'green-600',
	successHover: 'green-700',
	secondary: 'gray-500',
	secondaryHover: 'gray-600',
	danger: 'red-600',
	dangerHover: 'red-700',
	warning: 'orange-600',
	warningHover: 'orange-700',
	password: 'gray-300',
	passwordHover: 'gray-500'
};

// bg-white bg-blue-700 bg-blue-600 bg-purple-700 bg-purple-600 bg-red-700 bg-red-600 bg-orange-700 bg-orange-600 text-white
// hover:bg-white hover:bg-blue-700 hover:bg-blue-600 hover:bg-purple-700 hover:bg-purple-600 hover:bg-red-700 hover:bg-red-600 hover:bg-orange-700 hover:bg-orange-600 text-white
// bg-green-600 bg-green-700 hover:green-700 text-green border-green-600 border-green-700 hover:text-green-700
// border-blue-700 border-blue-600 border-purple-700 border-purple-600 border-red-700 border-red-600 border-orange-700 border-orange-600 text-white
// bg-gray-600 bg-gray-700 hover:gray-700 text-gray border-gray-600 border-gray-700 hover:text-gray-700
// bg-gray-400 bg-gray-700 hover:gray-700 text-gray border-gray-400 border-gray-700 hover:text-gray-700
// bg-blue-50 bg-gray-50 bg-red-50 bg-yellow-50 bg-green-50
// border-gray-500 bg-gray-500 bg-gray-600 border-gray-600 border-gray-500
export type ColorType =
	| 'primary'
	| 'secondary'
	| 'success'
	| 'danger'
	| 'warning'
	| 'password';

export const createColorForButton = (color: ColorType) => {
	const normalClass = defaultColors[color];
	const hoverClass = defaultColors[`${color}Hover`];

	return `bg-${normalClass} border border-${normalClass} hover:bg-${hoverClass} text-white bg-opacity-100`;
};

export const createColorForOutlinedButton = (
	color: ColorType,
	isActive: boolean
) => {
	const normalClass = isActive ? defaultColors[`${color}`] : 'white';
	const borderClass = defaultColors[color];
	const textClass = isActive ? 'white' : defaultColors[`${color}`];
	const hoverColor = isActive ? 'white' : defaultColors[`${color}Hover`];
	const hoverClass = defaultColors[`${color}Hover`];
	const bgHoverClass =
		'hover:bg-' + defaultColors[`${color}Hover`].split('-')[0] + '-50';

	return `bg-${normalClass} text-${textClass} border ${bgHoverClass} border-${borderClass} ${hoverColor}  hover:border-${hoverClass}`;
};
