import React from "react";
import classNames from "classnames";

export interface NavProps extends React.HTMLAttributes<HTMLUListElement> {
  [key: string]: any;
  tabs?: boolean;
  pills?: boolean;
  vertical?: boolean | string;
  horizontal?: string;
  justified?: boolean;
  fill?: boolean;
  navbar?: boolean;
  card?: boolean;
  tag?: React.ElementType;
}

const getVerticalClass = (vertical: string | boolean) => {
  if (vertical === false) {
    return false;
  } else if (vertical === true || vertical === "xs") {
    return "flex-column";
  }

  return `flex-${vertical}-column`;
};

export const Nav: React.FC<NavProps> = ({
  tag = "ul",
  vertical = false,
  ...props
}) => {
  const {
    className,
    cssModule,
    tabs,
    pills,
    horizontal,
    justified,
    fill,
    navbar,
    card,
    ...attributes
  } = props;

  const Tag = tag;

  const classes = classNames(
    className,
    navbar ? "navbar-nav" : "nav",
    horizontal ? `justify-content-${horizontal}` : false,
    getVerticalClass(vertical),
    {
      "nav-tabs": tabs,
      "card-header-tabs": card && tabs,
      "nav-pills": pills,
      "card-header-pills": card && pills,
      "nav-justified": justified,
      "nav-fill": fill,
    }
  );

  return <Tag {...attributes} className={classes} />;
};
