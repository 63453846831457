import React from 'react';

type Props = {};

export const ModalFooter: React.FC<Props> = ({ children }) => {
	return (
		<div className='px-6 py-1.5 lg:py-4 overflow-hidden flex justify-between'>
			{children}
		</div>
	);
};
