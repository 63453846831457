import classNames from "classnames";
import React from "react";

export interface CardProps extends React.HTMLAttributes<HTMLElement> {
  [key: string]: any;
  tag?: React.ElementType;
  inverse?: boolean;
  color?: string;
  body?: boolean;
  outline?: boolean;
  innerRef?: React.Ref<HTMLElement>;
}

type Props = CardProps;

export const Card: React.FC<Props> = ({ tag = "div", children, ...props }) => {
  const {
    className,
    cssModule,
    color,
    body,
    inverse,
    outline,
    innerRef,
    ...attributes
  } = props;

  const Tag = tag;

  const classes = classNames(
    className,
    "card",
    inverse ? "text-white" : false,
    body ? "card-body" : false,
    color ? `${outline ? "border" : "bg"}-${color}` : false
  );

  return <Tag {...attributes} className={classes} ref={innerRef}>{children}</Tag>;
};
