import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export interface BadgeProps extends React.HTMLAttributes<HTMLElement> {
  [key: string]: any;
  color?: string;
  pill?: boolean;
  tag?: React.ElementType;
  innerRef?: React.Ref<HTMLElement>;
}

export const Badge: React.FC<BadgeProps> = ({
  color = "secondary",
  pill = false,
  tag = "span",
  ...props
}) => {
  let { className, cssModule, innerRef, ...attributes } = props;

  let Tag = tag;

  const classes = classNames(
    className,
    "badge",
    "bg-" + color,
    pill ? "rounded-pill" : false
  );

  if (attributes.href && Tag === "span") {
    Tag = "a";
  }

  return <Tag {...attributes} className={classes} ref={innerRef} />;
};
