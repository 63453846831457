import classNames from 'classnames';
import React from 'react';

type Props = {
	className?: string;
};

export const ModalBody: React.FC<Props> = ({ children, className }) => {
	return (
		<div
			className={classNames(
				'modal-body p-6 bg-white text-gray-700 text-sm customscroll overflow-y-auto',
				className
			)}
		>
			{children}
		</div>
	);
};
