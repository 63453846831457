import classNames from "classnames";
import { isObject } from "lodash";

const colWidths = ["xs", "sm", "md", "lg", "xl", "xxl"];

export type ColumnProps =
  | string
  | boolean
  | number
  | {
      size?: boolean | number | string;
      offset?: string | number;
      order?: string | number;
    };

export interface ColProps extends React.HTMLAttributes<HTMLDivElement> {
  [key: string]: any;
  tag?: React.ElementType;
  xs?: ColumnProps;
  sm?: ColumnProps;
  md?: ColumnProps;
  lg?: ColumnProps;
  xl?: ColumnProps;
  xxl?: ColumnProps;

  // custom widths
  widths?: string[];
}

const getColumnSizeClass = (
  isXs: boolean,
  colWidth: string,
  colSize: string | boolean
) => {
  if (colSize === true || colSize === "") {
    return isXs ? "col" : `col-${colWidth}`;
  } else if (colSize === "auto") {
    return isXs ? "col-auto" : `col-${colWidth}-auto`;
  }

  return isXs ? `col-${colSize}` : `col-${colWidth}-${colSize}`;
};

export const getColumnClasses = (
  attributes: { [x: string]: any },
  cssModule: any,
  widths = colWidths
) => {
  const colClasses: string[] = [];

  widths.forEach((colWidth, i) => {
    let columnProp: any = attributes[colWidth];

    delete attributes[colWidth];

    if (!columnProp && columnProp !== "") {
      return;
    }

    const isXs = !i;

    if (isObject(columnProp)) {
      const columnPropAsAny: any = columnProp;
      const colSizeInterfix = isXs ? "-" : `-${colWidth}-`;
      const colClass = getColumnSizeClass(isXs, colWidth, columnPropAsAny.size);

      colClasses.push(
        classNames({
          [colClass]: columnPropAsAny.size || columnPropAsAny.size === "",
          [`order${colSizeInterfix}${columnPropAsAny.order}`]:
            columnPropAsAny.order || columnPropAsAny.order === 0,
          [`offset${colSizeInterfix}${columnPropAsAny.offset}`]:
            columnPropAsAny.offset || columnPropAsAny.offset === 0,
        })
      );
    } else {
      const colClass = getColumnSizeClass(isXs, colWidth, columnProp);
      colClasses.push(colClass);
    }
  });

  return {
    colClasses,
    attributes,
  };
};

export const Col: React.FC<ColProps> = ({
  tag = "div",
  widths = colWidths,
  ...props
}) => {
  const { className, children, ...attributes } = props;

  const Tag = tag;

  let { attributes: modifiedAttributes, colClasses } = getColumnClasses(
    attributes,
    widths
  );

  if (!colClasses.length) {
    colClasses.push("col");
  }

  const classes = classNames(className, colClasses);

  return <Tag {...modifiedAttributes} className={classes}>{children}</Tag>;
};
