import React from 'react';
import classNames from 'classnames';
import Dropdown from './Dropdown';

export interface InputGroupProps extends React.HTMLAttributes<HTMLElement> {
  [key: string]: any;
  tag?: React.ElementType;
  size?: string;
}

export const InputGroup: React.FC<InputGroupProps> = ({tag = 'div', ...props}) => {
  const {
    className,
    type,
    size,
    ...attributes
  } = props;

  const Tag = tag;

  const classes = classNames(
    className,
    'input-group',
    size ? `input-group-${size}` : null
  );

  if (props.type === 'dropdown') {
    return <Dropdown {...attributes} className={classes} />
  }

  return (
    <Tag {...attributes} className={classes} />
  );
};