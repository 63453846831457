import classNames from "classnames";
import React from "react";

export interface FormProps extends React.FormHTMLAttributes<HTMLFormElement> {
  [key: string]: any;
  inline?: boolean;
  tag?: React.ElementType;
  innerRef?: React.Ref<HTMLFormElement>;
}

type Props = FormProps;

export const Form: React.FC<Props> = ({ tag = 'form', children, ...props }) => {
  const {
    className,
    cssModule,
    inline,
    innerRef,
    ...attributes
  } = props;

  const Tag = tag;

  const classes = classNames(className, inline ? "form-inline" : false);

  return <Tag {...attributes} ref={innerRef} className={classes}>{children}</Tag>;
};
