import React from "react";
import classNames from "classnames";
import { Transition } from "react-transition-group";
import {
  omit,
  pick,
  TransitionPropTypeKeys,
  TransitionTimeouts,
} from "./utils";

const defaultProps = {
  ...(Transition as any).defaultProps,
  tag: "div",
  baseClass: "fade",
  baseClassActive: "show",
  timeout: TransitionTimeouts.Fade,
  appear: true,
  enter: true,
  exit: true,
  in: true,
};

export interface FadeProps extends React.HTMLAttributes<HTMLElement> {
  [key: string]: any;
  in?: boolean;
  baseClass?: string;
  baseClassIn?: string;
  tag?: React.ElementType;
  transitionAppearTimeout?: number;
  transitionEnterTimeout?: number;
  transitionLeaveTimeout?: number;
  transitionAppear?: boolean;
  transitionEnter?: boolean;
  transitionLeave?: boolean;
  onLeave?: () => void;
  onEnter?: () => void;
  innerRef?: React.Ref<HTMLElement>;
}

export function Fade({ ...props }: FadeProps) {
  const propsWithDefaults = {
    ...defaultProps,
    ...props,
  };

  const {
    className,
    cssModule,
    baseClass,
    baseClassActive,
    children,
    tag: Tag,
    innerRef,
    ...otherProps
  } = propsWithDefaults;

  const transitionProps: any = pick(otherProps, TransitionPropTypeKeys);
  const childProps = omit(otherProps, TransitionPropTypeKeys);

  return (
    <Transition {...transitionProps}>
      {(status: string) => {
        const isActive = status === "entered";
        const classes = classNames(
          className,
          baseClass,
          isActive && baseClassActive
        );
        return (
          <Tag className={classes} {...childProps} ref={innerRef}>
            {children}
          </Tag>
        );
      }}
    </Transition>
  );
}

Fade.defaultProps = defaultProps;

export default Fade;