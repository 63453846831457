import classNames from "classnames";
import React from "react";

export interface FormFeedbackProps extends React.HTMLAttributes<HTMLElement> {
  [key: string]: any;
  tag?: React.ElementType;
  valid?: boolean;
  tooltip?: boolean;
  className?: string;
}

export const FormFeedback: React.FC<FormFeedbackProps> = ({
  tag = "div",
  valid = undefined,
  className,
  ...props
}) => {
  const {
    cssModule,
    children,
    tooltip,
    ...attributes
  } = props;

  const Tag = tag;

  const validMode = tooltip ? "tooltip" : "feedback";

  // const classes =
  //   (classNames(
  //     className,
  //     valid ? ` text-green-500` : ` text-red-500`
  //   ),
  //   cssModule);

  const classes = `${className}${valid ? ' text-green-500' : ' text-red-500'}`

  return <Tag {...attributes} className={`feedback ${classes}`}>{children}</Tag>;
};
