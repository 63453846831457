import React from 'react';
import classNames from 'classnames';

export interface ContainerProps extends React.HTMLAttributes<HTMLElement> {
  [key: string]: any;
  tag?: React.ElementType;
  fluid?: boolean | string;
}

export const Container: React.FC<ContainerProps> = ({tag = 'div', ...props}) => {
  const {
    className,
    cssModule,
    fluid,
    children,
    ...attributes
  } = props;

  const Tag = tag;

  let containerClass = 'container';
  if (fluid === true) {
    containerClass = 'container-fluid';
  }
  else if (fluid) {
    containerClass = `container-${fluid}`;
  }

  const classes = classNames(
    className,
    containerClass
  );

  return (
    <Tag {...attributes} className={className}>{children}</Tag>
  );
};
