import React, { useCallback, useContext, useEffect } from "react";
import { FiAlertTriangle, FiCheckCircle, FiInfo, FiX, FiXCircle } from "react-icons/fi";
import { ToastContext, ToastType } from "./toasts-context";

type Props = {
  message: string;
  type: ToastType;
  duration: number;
  isHiding: boolean;
  id: string;
};

const translations = {
  success: 'Sukces',
  danger: 'Błąd',
  warning: 'Ostrzeżenie',
  info: 'Informacja'
}

const icons ={
  success: <FiCheckCircle />,
  danger: <FiXCircle />,
  warning: <FiAlertTriangle />,
  info: <FiInfo />
}

const colors = {
  success: 'green',
  danger: 'red',
  warning: 'yellow',
  info: 'blue'
}

export const ToastItem: React.FC<Props> = ({
  type,
  message,
  isHiding,
  duration,
  id,
}) => {
  const shouldShowCloseButton = duration < 0;

  const { dispatch } = useContext(ToastContext);

  const onCloseClick = useCallback(() => {
    dispatch({
      type: "hide",
      payload: {
        id,
      },
    });
  }, [dispatch]);

  const delay = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  useEffect(() => {
    if (duration > -1) {
      (async () => {
        await delay(duration - 500);

        dispatch({
          type: "prepareHiding",
          payload: {
            id,
          },
        });

        await delay(500);

        dispatch({
          type: "hide",
          payload: {
            id,
          },
        });
      })();
    }
  });

  return (
    <div className="toast">
      <div className={isHiding ? "fade-out" : ""}>
        <div className="flex flex-col justify-center">
          <div
            /* bg-green-600 bg-red-600 bg-blue-600 bg-orange-600 */
            className={`bg-${colors[type]}-600 ` + 'shadow-lg mx-auto w-96 max-w-full text-sm pointer-events-auto bg-clip-padding rounded-lg block mb-3'}
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
            data-mdb-autohide="false"
          >
            <div className={`bg-${colors[type]}-600 flex justify-between items-center py-2 px-3 bg-clip-padding border-b border-${colors[type]}-500 rounded-t-lg`}>
              <p className="font-bold text-white flex items-center">{translations[type]}</p>
              <div className="flex items-center">
                <p className="text-white opacity-90 text-xs">Teraz</p>
                {shouldShowCloseButton && (
                  <button
                    type="button"
                    onClick={onCloseClick}
                    className="btn-close btn-close-white box-content w-4 h-4 ml-2 text-white border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-white hover:opacity-75 hover:no-underline"
                    data-mdb-dismiss="toast"
                    aria-label="Close"
                  >
                    <FiX size={16} />
                  </button>
                )}
              </div>
            </div>
            <div className={`p-3 bg-${colors[type]}-600 rounded-b-lg break-words text-white`}>
              <span
                dangerouslySetInnerHTML={{
                  __html: message,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
