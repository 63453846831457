import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export interface FormGroupProps extends React.HTMLAttributes<HTMLDivElement> {
  [key: string]: any;
  row?: boolean;
  check?: boolean;
  inline?: boolean;
  floating?: boolean;
  disabled?: boolean;
  tag?: React.ElementType;
}

export const FormGroup: React.FC<FormGroupProps> = ({ tag = 'div', ...props }) => {
  const {
    className,
    cssModule,
    row,
    disabled,
    check,
    inline,
    floating,
    ...attributes
  } = props;

  const Tag = tag;

  const formCheck = check || props.switch;

  const classes = classNames(
    className,
    row ? 'row' : false,
    formCheck ? 'form-check' : false,
    props.switch ? 'form-switch' : false,
    formCheck && inline ? 'form-check-inline' : false,
    formCheck && disabled ? 'disabled' : false,
    floating && 'form-floating'
  );

  if (Tag === 'fieldset') {
    attributes.disabled = disabled;
  }

  return (
    <Tag {...attributes} className={`formgroup ${classes}`} />
  );
};
