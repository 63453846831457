// Use it as placeholder for real translations

export const translate = (key: string, context: any = {}) => {
  if (!context) {
    return key;
  }

  var result = key;
  for (let prop in context) {
    result = result.replace(new RegExp("{" + prop + "}", "g"), context[prop]);
  }
  return result;
};

export const useTranslate = () => {
  return translate;
};
