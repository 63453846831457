import classNames from "classnames";
import React from "react";

export interface NavLinkProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  [key: string]: any;
  tag?: React.ElementType;
  innerRef?: React.Ref<HTMLAnchorElement>;
  disabled?: boolean;
  active?: boolean;
}

export const NavLink: React.FC<NavLinkProps> = ({ tag = "a", ...props }) => {
  const onClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (props.disabled) {
      e.preventDefault();
      return;
    }

    if (props.href === "#") {
      e.preventDefault();
    }

    if (props.onClick) {
      props.onClick(e);
    }
  };

  const Tag = tag;

  let { className, active, innerRef, ...attributes } = props;

  const classes = classNames(className, "cursor-pointer", {
    disabled: attributes.disabled,
    active: active,
  });

  return (
    <Tag {...attributes} ref={innerRef} onClick={onClick} className={classes} />
  );
};
