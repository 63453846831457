import classNames from "classnames";
import React from "react";
import {
  ColorType,
  createColorForButton,
  createColorForOutlinedButton,
} from "./default-colors";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  [key: string]: any;
  outline?: boolean;
  active?: boolean;
  block?: boolean;
  color?: ColorType;
  tag?: React.ElementType;
  innerRef?: React.Ref<HTMLButtonElement>;
  size?: string;
  close?: boolean;
}

type Props = ButtonProps;

export const Button: React.FC<Props> = ({
  color = "secondary",
  tag = "button",
  block = false,
  children,
  ...rest
}) => {
  let {
    active,
    "aria-label": ariaLabel,
    className,
    close,
    cssModule,
    outline,
    size,
    innerRef,
    ...attributes
  } = rest;

  let Tag = tag;

  const sizes: Record<string, string> = {
    label: "py-3.5 text-sm self-center -ml-1 rounded-r-lg",
    sm: "px-3 py-1.5 text-xs rounded",
    almostSm: "px-4 py-2 text-sm rounded-lg",
    md: "px-5 py-2.5 text-md rounded-lg",
    lg: "px-5.5 py-3 text-lg rounded-xl",
  };

  const classes = classNames(
    "px-5 py-2.5 cursor-pointer transition-colors duration-150",
    outline
      ? createColorForOutlinedButton(
          color,
          Boolean(typeof active !== undefined ? active : true)
        )
      : createColorForButton(color),
    close,
    // color ? colors[color] : colors['primary'],
    size ? sizes[size] : sizes["md"],
    attributes.disabled ? "text-gray-400" : "",
    className,
    block && Boolean(block) ? "w-full" : false,
    { active }
  );

  if (attributes.href && Tag === "button") {
    Tag = "a";
  }

  const defaultAriaLabel = close ? "Close" : null;

  return (
    <Tag
      type={Tag === "button" && attributes.onClick ? "button" : undefined}
      {...attributes}
      className={classes}
      ref={innerRef}
      aria-label={ariaLabel || defaultAriaLabel}
    >
      {children}
    </Tag>
  );
};
